import { CssBaseline, GlobalStyles } from '@mui/material';
import { useGetWhiteLabel } from '@portal-middleware/hooks';
import { NotificationsProvider, PageNotFound } from '@vestwell-frontend/ui';

import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { useLocation } from 'react-use';

import { Root } from '../components';
import { ConfigProvider } from '../contexts';
import {
  AccountLockedPage,
  ForgotPasswordPage,
  LoginAndSecurityPage,
  MfaNudgeAppSetupPage,
  MfaNudgeCompletedPage,
  MfaNudgeMethodPage,
  MfaNudgePage,
  MfaNudgePhoneSetupPage,
  MfaNudgeStartPage,
  MfaNudgeVerifyPage,
  MfaPage,
  PersonasPage,
  ResetPasswordExpiredPage,
  ResetPasswordPage,
  SignInPage
} from '../pages';
import { AccountSetupPage } from '../pages/AccountSetupPage';
import { routes } from './routes';

export const AppRouter = () => {
  const path = useLocation();

  const whitelabel = useGetWhiteLabel({
    query: {
      cacheTime: 10 * 1000 * 60,
      useErrorBoundary: true
    }
  });

  return (
    <>
      <CssBaseline />
      <NotificationsProvider>
        <ConfigProvider>
          <GlobalStyles
            styles={theme => ({
              body: {
                backgroundColor:
                  theme.palette[
                    path.pathname === routes.PERSONAS ? 'grey900' : 'grey700'
                  ].main
              },
              'div#root': {
                justifyContent: 'center',
                overflowY: 'auto'
              }
            })}
          />
          <BrowserRouter>
            <Routes>
              <Route element={<Root />} path=''>
                <Route
                  element={<AccountLockedPage />}
                  path={routes.ACCOUNT_LOCKED}
                />
                <Route
                  element={<AccountSetupPage />}
                  path={routes.ACCOUNT_SETUP}
                />
                <Route
                  element={<LoginAndSecurityPage />}
                  path={routes.LOGIN_AND_SECURITY}
                />
                <Route element={<MfaPage />} path={routes.MFA_VERIFICATION} />
                <Route element={<MfaNudgePage />} path={routes.MFA_NUDGE}>
                  <Route
                    element={<MfaNudgeAppSetupPage />}
                    path={`${routes.MFA_NUDGE}/app`}
                  />
                  <Route
                    element={<MfaNudgeCompletedPage />}
                    path={`${routes.MFA_NUDGE}/completed`}
                  />
                  <Route
                    element={<MfaNudgeMethodPage />}
                    path={`${routes.MFA_NUDGE}/method`}
                  />
                  <Route
                    element={<MfaNudgePhoneSetupPage />}
                    path={`${routes.MFA_NUDGE}/phone`}
                  />
                  <Route
                    element={<MfaNudgeVerifyPage />}
                    path={`${routes.MFA_NUDGE}/verify`}
                  />
                  <Route
                    element={<MfaNudgeStartPage />}
                    path={`${routes.MFA_NUDGE}`}
                  />
                </Route>
                <Route
                  element={<ForgotPasswordPage />}
                  path={routes.FORGOT_PASSWORD}
                />
                <Route element={<PersonasPage />} path={routes.PERSONAS} />
                <Route
                  element={<ResetPasswordPage />}
                  path={routes.RESET_PASSWORD}
                />
                <Route
                  element={<ResetPasswordExpiredPage />}
                  path={routes.RESET_PASSWORD_EXPIRED}
                />
                <Route element={<SignInPage />} path={routes.SIGN_IN} />
              </Route>
              <Route
                element={
                  <PageNotFound
                    legacyPaths={[routes.LOGOUT]}
                    supportEmail={whitelabel.data.support?.participant?.email}
                  />
                }
                path='*'
              />
            </Routes>
          </BrowserRouter>
        </ConfigProvider>
      </NotificationsProvider>
    </>
  );
};
