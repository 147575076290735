import { ArrowBack } from '@mui/icons-material';
import { Stack } from '@mui/material';
import { useDocumentTitle } from '@vestwell-frontend/hooks';
import { Button, Text } from '@vestwell-frontend/ui';

import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import {
  LoginSettingsSection,
  MfaSection,
  RecentLoginsSection
} from '../components';

export function LoginAndSecurityPage() {
  useDocumentTitle('Login & Security');

  const navigate = useNavigate();

  const onBack = useCallback(() => {
    navigate(-1);
  }, []);

  return (
    <Stack my={8} spacing={6}>
      <Stack>
        <Button
          className='self-center'
          onClick={onBack}
          startIcon={<ArrowBack fontSize='small' />}
          variant='inline'>
          Back
        </Button>
        <Text
          align='center'
          className='w-100'
          color='black'
          mb={6}
          variant='b2'>
          Login & Security
        </Text>
      </Stack>
      <LoginSettingsSection />
      <MfaSection />
      <RecentLoginsSection />
    </Stack>
  );
}

LoginAndSecurityPage.displayName = 'LoginAndSecurityPage';
