import { Alert } from '@mui/material';
import { useGetSessionInfo, usePutResetPassword } from '@sentinel/hooks';
import { validatePassword } from '@sentinel/utils';
import {
  PasswordCheckList,
  validatePasswords
} from '@vestwell-frontend/elements';
import { useDocumentTitle, useNotifications } from '@vestwell-frontend/hooks';
import { Form, FormField, Text } from '@vestwell-frontend/ui';

import { useCallback, useId, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { useUpdateEffect } from 'react-use';
import * as yup from 'yup';

import { SubmitButton } from '../components';
import { routes } from '../router/routes';

export function ResetPasswordPage() {
  useDocumentTitle('Reset Password');

  const navigate = useNavigate();
  const notify = useNotifications();

  const sessionInfo = useGetSessionInfo('password', {
    query: {
      refetchOnWindowFocus: true,
      useErrorBoundary: true
    }
  });

  const resetPassword = usePutResetPassword();
  const describedById = useId();

  const validate = useMemo(
    () =>
      validatePasswords(
        yup.object().shape({
          password: validatePassword([sessionInfo.data?.email || '']).required(
            'Required'
          ),
          passwordConfirm: yup
            .string()
            .oneOf([yup.ref('password'), undefined], 'Passwords Must Match')
        })
      ),
    [sessionInfo.data?.email]
  );

  useUpdateEffect(() => {
    if (resetPassword.data) {
      notify.success('Password reset successful');
      navigate(routes.SIGN_IN);
    }
  }, [resetPassword.data]);

  const onSubmit = useCallback(async values => {
    await resetPassword.mutateAsync({
      data: {
        password: values.password
      }
    });
  }, []);

  return (
    <>
      <Text
        align='center'
        color='emphasize'
        data-testid='resetPasswordTitle'
        mb={8}
        variant='b2'>
        Reset Password
      </Text>
      <Form
        className='w-64'
        initialValues={{
          password: '',
          passwordConfirm: ''
        }}
        onSubmit={onSubmit}
        validate={validate}>
        <FormField
          aria-describedby={describedById}
          className='w-64 mb-4'
          fieldClassName='w-64'
          label='Password'
          name='password'
          revealable
          type='password'
        />
        <FormField
          className='w-64'
          fieldClassName='w-64'
          label='Confirm Password'
          name='passwordConfirm'
          type='password'
        />
        <div className='flex flex-col'>
          {resetPassword.error && (
            <Alert className='mt-0 mb-0 w-fit-content' severity='error'>
              {resetPassword.error?.message}
            </Alert>
          )}
          <SubmitButton>Save New Password</SubmitButton>
          <PasswordCheckList describedById={describedById} />
        </div>
      </Form>
    </>
  );
}

ResetPasswordPage.displayName = 'ResetPasswordPage';
