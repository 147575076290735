import { Alert, Unstable_Grid2 as Grid } from '@mui/material';
import {
  GetMfaMethodsResDto,
  usePostMfaSend,
  usePostMfaVerify
} from '@sentinel/hooks';
import {
  Form,
  FormatPhoneNumber,
  FormRadioGroup,
  RadioButton,
  Text,
  VerificationCodeInput
} from '@vestwell-frontend/ui';

import { FC, memo, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { useUpdateEffect } from 'react-use';
import { useLocalStorage } from 'usehooks-ts';
import * as yup from 'yup';

import { routes } from '../router/routes';
import { ResendOtpButton } from './ResendOtpButton';
import { SubmitButton } from './SubmitButton';

export type PhoneVerificationProps = {
  onSubTypeSelect: (values: {
    mfaEntryId: number;
    subType: 'text' | 'voice';
  }) => Promise<void>;
  phone: Partial<GetMfaMethodsResDto> & {
    subType?: 'text' | 'voice';
  };
};

const subTypeSchema = yup.object().shape({
  subType: yup.string().required('Required')
});

const schema = yup.object().shape({
  phoneVerification: yup
    .string()
    .min(6, 'Must be at least 6 digits')
    .required('Required')
});

export const PhoneVerification: FC<PhoneVerificationProps> = memo(props => {
  const navigate = useNavigate();

  const postMfaSend = usePostMfaSend();
  const postMfaVerify = usePostMfaVerify();

  const [, setCodeLastSentTime] = useLocalStorage('codeLastSentTime', {});

  const onSubmit = useCallback(
    async values => {
      await postMfaVerify.mutateAsync({
        data: {
          code: values.phoneVerification,
          //@ts-expect-error
          mfaEntryId: props.phone.mfaEntryId,
          subType: props.phone.subType,
          type: 'phone'
        }
      });
    },
    [props.phone]
  );

  const onSubTypeSubmit = useCallback(
    async values => {
      await props.onSubTypeSelect({
        mfaEntryId: props.phone.mfaEntryId,
        subType: values.subType
      });
    },
    [props.phone, props.onSubTypeSelect]
  );

  const onResendCode = useCallback(async () => {
    const res = await postMfaSend.mutateAsync({
      data: {
        //@ts-expect-error
        mfaEntryId: props.phone.mfaEntryId,
        subType: props.phone.subType,
        type: 'phone'
      }
    });
    setCodeLastSentTime(prevState => ({
      ...prevState,
      [props.phone.mfaEntryId]: Date.now()
    }));
    console.log(res.code);
  }, [props.phone]);

  const isLoginDisabled = postMfaVerify.error?.message?.startsWith(
    'Your account has been temporarily locked'
  );

  useUpdateEffect(() => {
    if (postMfaVerify.data?.session?.isMfaVerified) {
      navigate(routes.PERSONAS);
    }
  }, [postMfaVerify.data?.session?.isMfaVerified]);

  return (
    <>
      <Grid>
        <Text
          align='center'
          className='w-100'
          data-testid='phoneVerificationTitle'
          variant='b2'>
          {!props.phone.subType
            ? 'Verify via phone'
            : props.phone.subType === 'text'
              ? 'Verify by Text Message'
              : 'Verify by Voice Call'}
        </Text>
      </Grid>
      {props.phone.subType ? (
        <Form
          className='contents'
          onChange={postMfaVerify.reset}
          onSubmit={onSubmit}
          validationSchema={schema}>
          <Grid
            alignItems='center'
            container
            display='flex'
            flexDirection='column'
            flexGrow={1}
            justifyContent='space-between'>
            <Grid xs={12}>
              <Text align='center' color='grey100' variant='i1'>
                {props.phone.subType === 'text'
                  ? 'Enter the 6-digit code we have texted to'
                  : 'Enter the 6-digit code provided on the call to'}{' '}
                <FormatPhoneNumber mask value={props.phone?.value} />.
              </Text>
            </Grid>

            <Grid
              alignItems='center'
              display='flex'
              flexDirection='column'
              flexGrow={1}
              justifyContent='center'
              xs={12}>
              <Text color='grey100' variant='f2'>
                Security Code
              </Text>
              <VerificationCodeInput
                disabled={postMfaSend.isLoading || isLoginDisabled}
                name='phoneVerification'
              />
              <ResendOtpButton
                className='mt-4 mb-2'
                disabled={isLoginDisabled}
                inputName='phoneVerification'
                mfaEntryId={props.phone.mfaEntryId}
                onClick={onResendCode}
                variant='inline'
              />
            </Grid>
            {postMfaVerify.error && (
              <Alert className='w-fit-content' severity='error'>
                {postMfaVerify.error.message}
              </Alert>
            )}
            <SubmitButton disabled={isLoginDisabled}>Continue</SubmitButton>
          </Grid>
        </Form>
      ) : (
        <Form
          className='contents'
          initialValues={{ subType: 'text' }}
          onSubmit={onSubTypeSubmit}
          validateOnMount={false}
          validationSchema={subTypeSchema}>
          <Grid flexGrow={1} mt={8}>
            <FormRadioGroup
              hideLabel
              label='Mfa Method'
              name='subType'
              variant='card'>
              <Text align='center' color='grey50' variant='g2'>
                Select a method for{' '}
                <FormatPhoneNumber mask value={props.phone?.value} />
              </Text>
              <RadioButton
                className='ml-0'
                label='Receive a text message'
                value='text'
              />
              <RadioButton
                className='ml-0'
                label='Receive a voice call'
                value='voice'
              />
            </FormRadioGroup>
          </Grid>
          <SubmitButton>Continue</SubmitButton>
        </Form>
      )}
    </>
  );
});

PhoneVerification.displayName = 'PhoneVerification';
