import { styled } from '@mui/material/styles';
import { SuspenseImage } from '@vestwell-frontend/ui';

import { FC } from 'react';

import { useConfig } from '../contexts';

const StyledBanner = styled(SuspenseImage)(props => ({
  [props.theme.breakpoints.up('sm')]: {
    alignSelf: 'center',
    borderBottomLeftRadius: '10px',
    borderBottomRightRadius: '10px',
    maxWidth: '1440px',
    position: 'fixed',
    top: 0,
    zIndex: -1
  }
}));

export const Banner: FC = () => {
  const config = useConfig();

  // todo:: update db entries path & extension
  return (
    !!config.banner && (
      <StyledBanner
        data-component='banner'
        src={config.banner
          .replace('/clients/login', '')
          .replace('.png', '.webp')}
      />
    )
  );
};

Banner.displayName = 'Banner';
