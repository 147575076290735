import { Overlay } from '@hermes/ui';
import { CircularProgress, Stack } from '@mui/material';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { Favicon } from '@vestwell-frontend/elements';
import { ApiBoundary, ThemeProvider } from '@vestwell-frontend/ui';

import type { AxiosError } from 'axios';
import { Suspense } from 'react';
import { createRoot } from 'react-dom/client';

import { AppRouter } from './router/AppRouter';

const queryClient = new QueryClient({
  defaultOptions: {
    mutations: {
      retry: false,
      useErrorBoundary: false
    },
    queries: {
      cacheTime: 60 * 1000 * 5,
      onError: (e: AxiosError) => {
        if (
          [401, 403].includes(e?.status) ||
          ['Unauthorized', 'Forbidden'].includes(e?.message)
        ) {
          window.location.href = window.location.origin;
        }
      },
      refetchOnMount: 'always',
      refetchOnReconnect: 'always',
      refetchOnWindowFocus: false,
      retry: (failureCount, error: AxiosError) =>
        (['ECONNABORTED', 'ENOTFOUND', 'ETIMEDOUT', 'ECONNREFUSED'].includes(
          error?.code
        ) ||
          [503, 504].includes(error?.status)) &&
        failureCount < 6,
      suspense: true
    }
  }
});

const root = createRoot(document.getElementById('root'));

root.render(
  <QueryClientProvider client={queryClient}>
    <ThemeProvider isWhitelabeled>
      <Suspense
        fallback={
          <Stack
            alignItems='center'
            height='100vh'
            justifyContent='center'
            sx={theme => ({ backgroundColor: theme.palette.grey900.main })}
            width='100vw'>
            <CircularProgress />
          </Stack>
        }>
        <Favicon />
        <Overlay />
        <ApiBoundary>
          <AppRouter />
        </ApiBoundary>
      </Suspense>
    </ThemeProvider>
  </QueryClientProvider>
);
