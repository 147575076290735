import {
  AccountBalanceOutlined,
  ChevronRight,
  OpenInNewOutlined,
  PersonOutlineOutlined
} from '@mui/icons-material';
import {
  Avatar,
  AvatarProps,
  CircularProgress,
  Divider,
  List,
  ListItemButton,
  ListItemButtonProps,
  ListProps,
  ListSubheader,
  ListSubheaderProps,
  Stack,
  styled
} from '@mui/material';
import { ParticipantPersona, useGetPersonasV2 } from '@portal-middleware/hooks';
import { useGetSessionInfo, useGetStatus } from '@sentinel/hooks';
import { useDocumentTitle } from '@vestwell-frontend/hooks';
import {
  ContributionsIcon,
  PLACEHOLDER,
  SavingsIcon,
  Tab,
  TabList,
  TabListProps,
  TabPanel,
  Tabs,
  TabsProps,
  Text
} from '@vestwell-frontend/ui';

import { groupBy, sortBy } from 'lodash';
import { useCallback, useEffect, useMemo } from 'react';

import { useBackButtonBehavior, useNav } from '../hooks';
import { routes } from '../router/routes';

const planType = {
  '401k': {
    avatarBgColor: '#EBF4F8',
    avatarIcon: <ContributionsIcon sx={{ color: '#2C8FC5' }} />,
    nameForParticipant: 'Retirement Savings 401(k)',
    nameForSponsor: '401(k)'
  },
  '403b': {
    avatarBgColor: '#EBF4F8',
    avatarIcon: <ContributionsIcon sx={{ color: '#2C8FC5' }} />,
    nameForParticipant: 'Retirement Savings 403(b)',
    nameForSponsor: '403(b)'
  },
  ESA: {
    avatarBgColor: '#FFF2E9',
    avatarIcon: <SavingsIcon color='alert' />,
    nameForParticipant: 'Emergency Savings',
    nameForSponsor: 'Emergency Savings'
  },
  'Solo 401k': {
    avatarBgColor: '#EBF4F8',
    avatarIcon: <ContributionsIcon sx={{ color: '#2C8FC5' }} />,
    nameForParticipant: 'Retirement Savings Solo 401(k)',
    nameForSponsor: 'Solo 401(k)'
  },
  'State IRA': {
    avatarBgColor: '#F2F2F2',
    avatarIcon: <AccountBalanceOutlined color='grey100' />,
    nameForParticipant: 'State IRA',
    nameForSponsor: 'State IRA'
  },
  cash_balance: {
    avatarBgColor: '#EBF4F8',
    avatarIcon: <ContributionsIcon sx={{ color: '#2C8FC5' }} />,
    nameForParticipant: 'Cash Balance',
    nameForSponsor: 'Cash Balance'
  },
  vss: {
    avatarBgColor: '#F2F2F2',
    avatarIcon: <AccountBalanceOutlined color='grey100' />,
    nameForParticipant: 'Cash Balance',
    nameForSponsor: 'Cash Balance'
  }
} as const;

const StyledAvatar = styled(Avatar, {
  shouldForwardProp: prop => !['customBgColor'].includes(prop.toString())
})<AvatarProps & { customBgColor?: string }>(({ customBgColor, theme }) => ({
  backgroundColor: customBgColor || theme.palette.grey700.main,
  marginRight: theme.spacing(4)
}));

const StyledList = styled(List)<ListProps>(({ theme }) => ({
  backgroundColor: theme.palette.white.main,
  border: `${theme.spacing(0.25)} solid ${theme.palette.grey600.main}`,
  borderRadius: theme.spacing(1),
  boxShadow: theme.shadows[24],
  marginBottom: theme.spacing(6),
  padding: 0,
  [theme.breakpoints.down('sm')]: {
    width: '100%'
  },
  [theme.breakpoints.up('sm')]: {
    width: theme.spacing(160)
  }
}));

const StyledListItemButton = styled(ListItemButton)<ListItemButtonProps>(
  ({ theme }) => ({
    '&:last-of-type': {
      borderBottom: `${theme.spacing(0.25)} solid transparent`
    },
    alignItems: 'center',
    borderBottom: `${theme.spacing(0.25)} solid ${theme.palette.grey700.main}`,
    display: 'flex',
    justifyContent: 'space-between',
    padding: theme.spacing(4)
  })
);

const StyledListSubheader = styled(ListSubheader)<ListSubheaderProps>(
  ({ theme }) => ({
    alignItems: 'center',
    backgroundColor: theme.palette.aliceBlue.main,
    borderBottom: `${theme.spacing(0.25)} solid ${theme.palette.grey700.main}`,
    borderRadius: theme.spacing(1, 1, 0, 0),
    display: 'flex',
    height: theme.spacing(9.5)
  })
);

const StyledTabs = styled(Tabs)<TabsProps>(({ theme }) => ({
  padding: theme.spacing(0, 4),
  width: '100%'
}));

const StyledTabList = styled(TabList)<TabListProps>({
  display: 'flex',
  justifyContent: 'center',
  width: '100%'
});

const StyledTabPanel = styled(TabPanel)({
  alignItems: 'center',
  display: 'flex',
  flexDirection: 'column',
  width: '100%'
});

export function PersonasPage() {
  useDocumentTitle('Your Accounts');

  useBackButtonBehavior();

  const nav = useNav();

  const user = useGetSessionInfo('user', {
    query: {
      useErrorBoundary: true
    }
  });

  const personas = useGetPersonasV2();

  const status = useGetStatus({
    query: {
      refetchOnWindowFocus: true,
      useErrorBoundary: true
    }
  });

  const advisors = useMemo(() => {
    const grouped = groupBy(personas.data?.advisors || [], 'company');

    return Object.keys(grouped)
      .sort()
      .map(company => ({
        company,
        users: grouped[company]
      }));
  }, [personas.data]);

  const participants = useMemo(() => {
    const groupedNames = [
      //@ts-expect-error
      ...(personas.data?.stateSavers || []),
      ...(personas.data?.participants || [])
    ].reduce((acc, obj) => {
      const key = obj.name.toLowerCase().replace(/[^a-z0-9]/g, ''); // Convert to lowercase, remove spaces, and remove symbols
      acc[key] = (acc[key] || []).concat(obj);
      return acc;
    }, {});

    return Object.keys(groupedNames)
      .sort()
      .map(name => {
        const companies: { company: string; users: ParticipantPersona[] }[] =
          sortBy(
            Object.entries(groupBy(groupedNames[name], 'company')),
            'company'
          ).map(([company, users]) => ({
            company,
            users
          }));

        return {
          companies,
          name: companies[0].users[0].name
        };
      });
  }, [personas.data]);

  const sponsors = useMemo(() => {
    const grouped = groupBy(personas.data?.sponsors || [], 'company');

    const companies = Object.keys(grouped).sort();

    return companies.map(company => ({
      company,
      users: grouped[company]
    }));
  }, [personas.data]);

  const isTheOnlyTab = useMemo(
    () =>
      (personas.data?.advisors?.length ? 1 : 0) +
        (personas.data?.sponsors?.length ? 1 : 0) +
        (personas.data?.participants?.length ||
        //@ts-expect-error
        personas.data?.stateSavers?.length
          ? 1
          : 0) ===
      1,
    [personas.data]
  );

  const onClick = useCallback(async (loginUrl: string) => {
    const res = await status.refetch();
    if (res.isSuccess) {
      window.location.assign(loginUrl);
    }
  }, []);

  useEffect(() => {
    if (user.data?.hasMfaNudge && !user.data.isMfaEnabled) {
      nav.to(routes.MFA_NUDGE);
    }
  }, [user.data]);

  useEffect(() => {
    if (user.data?.isMfaEnabled && !user.data.isMfaVerified) {
      nav.to(routes.MFA_VERIFICATION);
    }
  }, [user.data]);

  useEffect(() => {
    if (user.data?.postLoginPath) {
      nav.external(user.data.postLoginPath);
    } else if (personas.data?.count === 1) {
      const timeout = setTimeout(
        () =>
          window.location.assign(
            personas.data?.advisors?.[0]?.loginUrl ||
              personas.data?.participants?.[0]?.loginUrl ||
              personas.data?.sponsors?.[0]?.loginUrl ||
              //@ts-expect-error
              personas.data?.stateSavers?.[0]?.loginUrl
          ),
        500
      );

      return () => {
        clearTimeout(timeout);
      };
    }
  }, [personas.data?.count, user.data?.postLoginPath]);

  return (
    <>
      {personas.data?.count < 2 || user.data?.postLoginPath ? (
        <CircularProgress className='mx-auto my-auto' />
      ) : (
        <>
          <Text
            align='center'
            className='w-100'
            color='grey50'
            pb={2}
            pt={6}
            variant='c2'>
            Your Accounts
          </Text>
          <StyledTabs
            defaultIndex={advisors.length ? 0 : participants.length ? 1 : 2}
            variant='small'>
            {isTheOnlyTab ? undefined : (
              <StyledTabList>
                {advisors.length ? (
                  <Tab title='Advisor' value={0} />
                ) : undefined}
                {participants.length ? (
                  <Tab title='Personal' value={1} />
                ) : undefined}
                {sponsors.length ? (
                  <Tab title='Employer' value={2} />
                ) : undefined}
              </StyledTabList>
            )}
            {advisors.length ? (
              <StyledTabPanel value={0}>
                {advisors.map(advisor => (
                  <StyledList
                    aria-labelledby={`${advisor.company}`}
                    component='nav'
                    data-company={advisor.company}
                    key={advisor.company}
                    subheader={
                      <StyledListSubheader
                        component='div'
                        data-company={advisor.company}
                        id={`${advisor.company}`}>
                        <Text color='grey100' mb={0} variant='g2'>
                          {advisor.company}
                        </Text>
                      </StyledListSubheader>
                    }>
                    {advisor.users.map(user => (
                      <StyledListItemButton
                        data-company={user.company}
                        data-name={user.name}
                        data-testid={user.advisorId}
                        key={user.advisorId}
                        onClick={() => onClick(user.loginUrl)}>
                        <Stack alignItems='center' direction='row'>
                          <StyledAvatar>
                            <PersonOutlineOutlined color='grey100' />
                          </StyledAvatar>
                          <div>
                            <Text color='grey50' mb={0} variant='e1'>
                              {user.name}
                            </Text>
                            <Text
                              color='grey100'
                              component='div'
                              mb={0}
                              variant='i1'>
                              ID: {user.advisorId}
                            </Text>
                          </div>
                        </Stack>
                        <ChevronRight color='primary' />
                      </StyledListItemButton>
                    ))}
                  </StyledList>
                ))}
              </StyledTabPanel>
            ) : undefined}
            {participants.length ? (
              <StyledTabPanel value={1}>
                {participants.map(participant => (
                  <Stack
                    alignItems='center'
                    key={participant.name}
                    width='100%'>
                    <Text
                      color='grey50'
                      data-component='listHeader'
                      data-name={participant.name}
                      id={`${participant.name}`}
                      variant='e2'>
                      {participant.name}
                    </Text>
                    {participant.companies.map(group => (
                      <StyledList
                        aria-labelledby={`${participant.name} ${group.company}`}
                        component='nav'
                        data-company={group.company}
                        data-name={participant.name}
                        key={`${participant.name} ${group.company}`}
                        subheader={
                          <StyledListSubheader
                            component='div'
                            data-company={group.company}
                            id={`${group.company}`}>
                            <Text color='grey100' mb={0} variant='g2'>
                              {group.company}
                            </Text>
                          </StyledListSubheader>
                        }>
                        {group.users.map(user => (
                          <StyledListItemButton
                            data-plantype={
                              planType[user.planType]?.nameForParticipant ??
                              user.planType
                            }
                            data-testid={user.participantId}
                            key={user.participantId}
                            onClick={() => onClick(user.loginUrl)}>
                            <Stack alignItems='center' direction='row'>
                              <StyledAvatar
                                customBgColor={
                                  planType[user.planType]?.avatarBgColor
                                }>
                                {planType[user.planType]?.avatarIcon || (
                                  <PersonOutlineOutlined color='grey100' />
                                )}
                              </StyledAvatar>
                              <div>
                                <Text color='grey50' mb={0} variant='e1'>
                                  {user.planType === 'vss'
                                    ? //@ts-expect-error: check for vss
                                      `${user.planName}${
                                        //@ts-expect-error: check for vss
                                        user.plansCount > 1 ? ' and others' : ''
                                      }`
                                    : planType[user.planType]
                                        ?.nameForParticipant ?? user.planType}
                                </Text>
                                {user.planType !== 'vss' && (
                                  <Text
                                    color='grey100'
                                    component='div'
                                    mb={0}
                                    variant='i1'>
                                    ID: {user.participantId}
                                  </Text>
                                )}
                              </div>
                            </Stack>
                            {user.planType === 'vss' ? (
                              <OpenInNewOutlined color='primary' />
                            ) : (
                              <ChevronRight color='primary' />
                            )}
                          </StyledListItemButton>
                        ))}
                      </StyledList>
                    ))}
                  </Stack>
                ))}
              </StyledTabPanel>
            ) : undefined}
            {sponsors.length ? (
              <StyledTabPanel value={2}>
                {sponsors.map(sponsor => (
                  <StyledList
                    component='nav'
                    data-company={sponsor.company}
                    key={sponsor.company}>
                    {sponsor.users.map(user => (
                      <StyledListItemButton
                        data-firmcompanyname={user.firmCompanyName}
                        data-plantype={
                          planType[user.planType]?.nameForSponsor ??
                          user.planType
                        }
                        data-testid={user.sponsorId}
                        key={user.sponsorId}
                        onClick={() => onClick(user.loginUrl)}>
                        <Stack alignItems='center' direction='row'>
                          <StyledAvatar
                            customBgColor={
                              planType[user.planType]?.avatarBgColor
                            }>
                            {planType[user.planType]?.avatarIcon || (
                              <PersonOutlineOutlined color='grey100' />
                            )}
                          </StyledAvatar>
                          <div>
                            <Text color='grey50' mb={0} variant='e1'>
                              {user.company}
                            </Text>
                            <Text
                              color='grey100'
                              component='div'
                              mb={0}
                              variant='i1'>
                              <Stack
                                direction={{ sm: 'row', xs: 'column' }}
                                divider={
                                  planType[user.planType] && (
                                    <Divider
                                      className='mx-2'
                                      flexItem
                                      orientation='vertical'
                                    />
                                  )
                                }>
                                {planType[user.planType] && (
                                  <b>
                                    {planType[user.planType]?.nameForSponsor}
                                  </b>
                                )}
                                {user.recordKeeperId === 4 &&
                                  user.firmCompanyName}
                                {user.recordKeeperId === 2
                                  ? `Voya ID: ${
                                      user.externalSponsorPlanId ?? PLACEHOLDER
                                    }`
                                  : `ID: ${user.sponsorPlanId ?? PLACEHOLDER}`}
                              </Stack>
                            </Text>
                          </div>
                        </Stack>
                        <ChevronRight color='primary' />
                      </StyledListItemButton>
                    ))}
                  </StyledList>
                ))}
              </StyledTabPanel>
            ) : undefined}
          </StyledTabs>
        </>
      )}
    </>
  );
}

PersonasPage.displayName = 'PersonasPage';
