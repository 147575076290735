import { KeyboardArrowRightOutlined } from '@mui/icons-material';
import { Alert, Unstable_Grid2 as Grid } from '@mui/material';
import { usePostMfaSetup, usePostMfaSetupVerify } from '@sentinel/hooks';
import {
  Button,
  Form,
  FormatPhoneNumber,
  FormFieldLabel,
  FormSaveButton,
  ModalBody,
  ModalFooter,
  Text,
  useWizard,
  VerificationCodeInput
} from '@vestwell-frontend/ui';

import { FC, useCallback } from 'react';
import * as yup from 'yup';

import { MfaSetupState } from '../config/mfaSetup';
import { ResendOtpButton } from './ResendOtpButton';

const schema = yup.object().shape({
  securityCode: yup
    .string()
    .min(6, 'Must be at least 6 digits')
    .required('Required')
});

export const MfaSetupVerifyCode: FC = () => {
  const ctx = useWizard<MfaSetupState>();

  const postMfaSetup = usePostMfaSetup({
    mutation: {
      onSuccess: res => {
        console.log(res.code);
      }
    }
  });

  const postMfaSetupVerify = usePostMfaSetupVerify({
    mutation: {
      onSuccess: () => {
        ctx.setState({
          methodVerified: true
        });
      }
    }
  });

  const onBack = useCallback(() => {
    ctx.setState({
      methodSelected: false
    });
  }, []);

  const onResendCode = useCallback(async () => {
    await postMfaSetup.mutateAsync({
      data: {
        type: ctx.state.method,
        value: ctx.state.value
      }
    });
  }, [ctx.state]);

  const onSubmit = useCallback(
    async values => {
      await postMfaSetupVerify.mutateAsync({
        data: {
          code: values.securityCode,
          //@ts-expect-error
          subType: ctx.state.subType
        }
      });
    },
    [ctx.state.subType]
  );

  const isDisabled = postMfaSetupVerify.error?.message?.startsWith(
    'Your account has been temporarily locked'
  );

  return (
    <Form
      className='contents'
      onChange={postMfaSetupVerify.reset}
      onSubmit={onSubmit}
      validateOnMount={false}
      validationSchema={schema}>
      <ModalBody>
        <Grid alignItems='center' container direction='column' spacing={4}>
          <Grid
            alignItems='center'
            display='flex'
            flexDirection='column'
            lg={12}>
            <Text className='text-center' mb={0}>
              Enter the 6-digit code{' '}
              {ctx.state.subType === 'text'
                ? 'we have sent to '
                : ctx.state.subType === 'voice'
                  ? 'provided on the call to '
                  : 'generated by your authentication app'}
              {ctx.state.method === 'phone' && (
                <>
                  <FormatPhoneNumber mask value={ctx.state.value} />.
                </>
              )}
            </Text>
          </Grid>
          <Grid
            alignItems='center'
            className='flex-grow'
            display='flex'
            flexDirection='column'
            justifyContent='center'
            lg={12}>
            <FormFieldLabel
              data-component='verificationCodeInputLabel'
              label='Security Code'
              name='securityCode'
            />
            <VerificationCodeInput
              className={ctx.state.method === 'phone' ? 'mb-4' : 'mb-8'}
              disabled={isDisabled}
              name='securityCode'
            />
            {ctx.state.method === 'phone' && (
              <ResendOtpButton
                className='mb-8'
                disabled={isDisabled}
                inputName={`${ctx.state.method}Verification`}
                onClick={onResendCode}
                variant='inline'
              />
            )}
          </Grid>
          {(postMfaSetup.error || postMfaSetupVerify.error) && (
            <Grid>
              <Alert
                classes={{ root: 'flex-shrink inline-flex' }}
                severity='error'
                variant='standard'>
                {postMfaSetup.error?.message ??
                  postMfaSetupVerify.error?.message}
              </Alert>
            </Grid>
          )}
        </Grid>
      </ModalBody>
      <ModalFooter>
        <Button disabled={isDisabled} onClick={onBack} variant='text'>
          Back
        </Button>
        <FormSaveButton
          className='ml-4'
          disabled={isDisabled}
          endIcon={<KeyboardArrowRightOutlined fontSize='small' />}>
          Confirm
        </FormSaveButton>
      </ModalFooter>
    </Form>
  );
};

MfaSetupVerifyCode.displayName = 'MfaSetupVerifyCode';
