import {
  useGetWhiteLabel,
  WhiteLabelEntityLogin
} from '@portal-middleware/hooks';

import { createContext, FC, ReactNode, useContext } from 'react';
import { createStore, StoreApi, useStore } from 'zustand';

type RootState = WhiteLabelEntityLogin & { subdomain: string };

type ConfigProviderProps = {
  children: ReactNode;
};

const ConfigContext = createContext<StoreApi<RootState>>(null);

export const useConfig = () => {
  const store = useContext(ConfigContext);
  return useStore(store) as RootState;
};

export const ConfigProvider: FC<ConfigProviderProps> = props => {
  const whitelabel = useGetWhiteLabel({
    query: {
      keepPreviousData: true,
      useErrorBoundary: true
    }
  });

  const configStore = createStore<RootState>()(() =>
    whitelabel.data
      ? { ...whitelabel.data.login, subdomain: whitelabel.data.subdomain }
      : ({} as RootState)
  );

  return whitelabel.isInitialLoading ? (
    <></>
  ) : (
    <ConfigContext.Provider value={configStore}>
      {props.children}
    </ConfigContext.Provider>
  );
};
