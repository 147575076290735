import { Link, Text } from '@vestwell-frontend/ui';

import { FC, memo } from 'react';

import { routes } from '../router/routes';

export type SignInButtonProps = {
  className?: string;
  forceLogout?: boolean;
};

export const SignInLink: FC<SignInButtonProps> = memo(props => {
  const to = props.forceLogout ? routes.LOGOUT : routes.SIGN_IN;

  return (
    <Text align='center' className={props.className}>
      <Link className='link-4' data-testid='signInLink' to={to}>
        Return to login
      </Link>
    </Text>
  );
});

SignInLink.defaultProps = {
  forceLogout: false
};

SignInLink.displayName = 'SignInLink';
