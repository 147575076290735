import { Alert, Unstable_Grid2 as Grid } from '@mui/material';
import { usePatchUserEmail, usePostUserEmailChange } from '@sentinel/hooks';
import { useNotifications } from '@vestwell-frontend/hooks';
import {
  Button,
  Form,
  FormFieldLabel,
  FormSaveButton,
  ModalBody,
  ModalFooter,
  Text,
  useWizard,
  VerificationCodeInput
} from '@vestwell-frontend/ui';

import { FC, useCallback } from 'react';
import * as yup from 'yup';

import { ChangeLoginEmailState } from '../config/changeLoginEmail';
import { ResendOtpButton } from './ResendOtpButton';

const schema = yup.object().shape({
  securityCode: yup
    .string()
    .min(6, 'Must be at least 6 digits')
    .required('Required')
});

type ChangeLoginEmailVerifyCodeProps = {
  onClose: () => void | Promise<void>;
};

export const ChangeLoginEmailVerifyCode: FC<
  ChangeLoginEmailVerifyCodeProps
> = props => {
  const ctx = useWizard<ChangeLoginEmailState>();
  const notify = useNotifications();

  const postUserEmailChange = usePostUserEmailChange({
    mutation: {
      onSuccess: data => {
        console.log(data?.code);
      }
    }
  });

  const patchUserEmail = usePatchUserEmail({
    mutation: {
      onError: () => {
        notify.error('Couldn’t update your login email at this time');
      },
      onSuccess: () => {
        ctx.setState({
          codeVerified: true
        });

        notify.success('Successfully updated login email');

        props.onClose();
      }
    }
  });

  const onBack = useCallback(() => {
    ctx.setState({
      emailVerified: false
    });
  }, []);

  const onResendCode = useCallback(async () => {
    await postUserEmailChange.mutateAsync({
      data: {
        email: ctx.state.newLoginEmail
      }
    });
  }, [ctx.state]);

  const onSubmit = useCallback(async values => {
    await patchUserEmail.mutateAsync({
      data: {
        token: values.securityCode
      }
    });
  }, []);

  const isDisabled = patchUserEmail.error?.message?.startsWith(
    'Your account has been temporarily locked'
  );

  return (
    <Form
      className='contents'
      onChange={patchUserEmail.reset}
      onSubmit={onSubmit}
      validateOnMount={false}
      validationSchema={schema}>
      <ModalBody>
        <Grid alignItems='center' container direction='column' spacing={4}>
          <Grid
            alignItems='center'
            display='flex'
            flexDirection='column'
            lg={12}>
            <Text mb={0} textAlign='center'>
              Enter the 6-digit code sent to{' '}
              <strong>{ctx.state.newLoginEmail}</strong>. The code will expire
              in 2 minutes
            </Text>
          </Grid>
          <Grid
            alignItems='center'
            className='flex-grow'
            display='flex'
            flexDirection='column'
            justifyContent='center'
            lg={12}>
            <FormFieldLabel
              data-component='verificationCodeInputLabel'
              label='Security Code'
              name='securityCode'
            />
            <VerificationCodeInput
              className='mb-4'
              disabled={isDisabled}
              name='securityCode'
            />
            <ResendOtpButton
              disabled={isDisabled}
              inputName='emailVerification'
              onClick={onResendCode}
              variant='inline'
            />
          </Grid>
          {(postUserEmailChange.error || patchUserEmail.error) && (
            <Grid>
              <Alert
                classes={{ root: 'flex-shrink inline-flex' }}
                severity='error'
                variant='standard'>
                {postUserEmailChange.error?.message ??
                  patchUserEmail.error?.message}
              </Alert>
            </Grid>
          )}
        </Grid>
      </ModalBody>
      <ModalFooter>
        <Button disabled={isDisabled} onClick={onBack} variant='inline'>
          Back
        </Button>
        <FormSaveButton
          className='ml-4'
          disabled={isDisabled || !!patchUserEmail.error}>
          Confirm
        </FormSaveButton>
      </ModalFooter>
    </Form>
  );
};

ChangeLoginEmailVerifyCode.displayName = 'ChangeLoginEmailVerifyCode';
