import { Button, Img, PLACEHOLDER, Text } from '@vestwell-frontend/ui';

import { FC } from 'react';
import { useToggle } from 'react-use';

export type MfaQrCodeProps = {
  isLoading: boolean;
  qrCode: string;
  secret: string;
};
export const MfaQrCode: FC<MfaQrCodeProps> = props => {
  const [isManualCode, toggleIsManualCode] = useToggle(false);

  return (
    <>
      <Img
        alt='QR Code'
        data-component='qrCode'
        height={175}
        isLoading={props.isLoading}
        src={props.qrCode}
        width={175}
      />
      {!isManualCode && (
        <Button onClick={toggleIsManualCode} variant='inline'>
          Can't scan barcode?
        </Button>
      )}
      {isManualCode && (
        <>
          <Text color='grey50' mb={2} variant='g1'>
            Input this verification code manually:
          </Text>
          <Text
            data-testid='qrCodeSecret'
            display='flex'
            flexWrap='wrap'
            fontFamily='Roboto Mono'
            fontSize={18}
            fontWeight={500}
            justifyContent='center'
            letterSpacing='0.065em'>
            {props.secret?.split('')?.map((letter, i) => (
              <span
                className={i % 4 === 0 ? 'text-bloodOrange' : 'text-grey50'}
                key={letter + i.toString()}>
                {letter}
              </span>
            )) ?? PLACEHOLDER}
          </Text>
        </>
      )}
    </>
  );
};
