import { useEffect } from 'react';

import { routes } from '../router/routes';
import { useNav } from './useNav';

export const useBackButtonBehavior = () => {
  const nav = useNav();

  useEffect(() => {
    const onBackButton = () => {
      nav.external(routes.LOGOUT);
    };

    window.history.pushState(null, null, window.location.pathname);

    window.addEventListener('popstate', onBackButton);

    return () => {
      window.removeEventListener('popstate', onBackButton);
    };
  }, []);
};
