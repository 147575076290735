import { Alert, Unstable_Grid2 as Grid } from '@mui/material';
import {
  useGetSessionInfo,
  usePostForgotPassword,
  usePostVerifyPassword
} from '@sentinel/hooks';
import { useNotifications } from '@vestwell-frontend/hooks';
import {
  Button,
  Form,
  FormField,
  FormSaveButton,
  Modal,
  ModalBody,
  ModalFooter,
  Text,
  VerticalAttribute
} from '@vestwell-frontend/ui';

import { FC, useCallback } from 'react';
import { useToggle } from 'react-use';
import * as yup from 'yup';

const schema = yup.object().shape({
  password: yup.string().required('Required')
});

export const PasswordResetModal: FC = () => {
  const notify = useNotifications();

  const user = useGetSessionInfo('user');

  const reset = usePostForgotPassword();
  const verify = usePostVerifyPassword();

  const [open, toggleOpen] = useToggle(false);

  const onChange = useCallback(() => {
    reset.reset();
    verify.reset();
  }, []);

  const onSubmit = useCallback(
    async values => {
      const res = await verify.mutateAsync({
        data: {
          password: values.password
        }
      });

      if (res.isValid) {
        await reset.mutateAsync({
          data: {
            email: user.data?.email
          }
        });
      }

      if (res.isValid) {
        notify.success('Reset Password Link Sent');
        toggleOpen();
      }
    },
    [user.data?.email]
  );

  return (
    <>
      <VerticalAttribute
        className='mb-0'
        label='Password'
        value={
          <Button aria-haspopup='dialog' onClick={toggleOpen} variant='inline'>
            Reset Password
          </Button>
        }
      />
      <Modal
        closeButton
        data-testid='resetPassword'
        header='Reset Password'
        isOpen={open}
        key={open ? '1' : '0'}
        onRequestClose={toggleOpen}>
        <Form
          className='contents'
          initialValues={{ password: '' }}
          onChange={onChange}
          onSubmit={onSubmit}
          validateOnMount={false}
          validationSchema={schema}>
          <ModalBody isLoading={user.isLoading}>
            <Grid container direction='column' spacing={4}>
              <Grid>
                <Text mb={0}>
                  For security, please enter your password for{' '}
                  <strong>{user.data?.email}</strong>. On password reset, a link
                  to reset your password will be sent to your login email.
                </Text>
              </Grid>
              <Grid
                className='flex-grow'
                display='flex'
                flexDirection='column'
                lg={6}
                md={9}
                xs={12}>
                <FormField
                  autoComplete='current-password'
                  autoFocus
                  className='w-100'
                  fieldClassName='w-100'
                  label='Password'
                  name='password'
                  revealable
                  type='password'
                />
                {(reset.error || verify.data?.isValid === false) && (
                  <Grid display='flex' flexDirection='column'>
                    <Alert
                      classes={{ root: 'flex-shrink inline-flex' }}
                      severity='error'
                      variant='standard'>
                      {reset.error?.message ?? (
                        <>
                          The password you entered is incorrect. Please try
                          again.
                        </>
                      )}
                    </Alert>
                  </Grid>
                )}
              </Grid>
            </Grid>
          </ModalBody>
          <ModalFooter>
            <Button onClick={toggleOpen} variant='text'>
              Cancel
            </Button>
            <FormSaveButton
              className='ml-4'
              data-testid='sendResetLink'
              disabled={!!user.isLoading || !!verify.error}>
              Send Reset Link
            </FormSaveButton>
          </ModalFooter>
        </Form>
      </Modal>
    </>
  );
};

PasswordResetModal.displayName = 'PasswordResetModal';
